import * as mathHelpers from 'assets/helpers/math';
import Grid from 'components/common/Grid';
import { random } from 'lodash';
import React, { useState, useRef } from 'react';
import { useScrollPosition } from '../../../../assets/helpers';
import useLocalStorageNumber from '../../../../assets/helpers/useLocalStorageNumber';
import HelloButton from './HelloButton';
import styles from './styles.module.scss';

let animating = false;
let percentage = 0;
let currentPercentage = 0;
const speed = 4;

const colorCombinations = [
  {
    backgroundColor: {
      // yellow
      red: 83,
      green: 83,
      blue: 83,
    },
    textColor: '#000000', // light purple
  },
  {
    backgroundColor: {
      red: 0,
      green: 0,
      blue: 0,
    },
    textColor: '#E5E5E5', // orange
  },
  {
    backgroundColor: {
      red: 242,
      green: 116,
      blue: 32,
    },
    textColor: '#000000', // light green
  },
];


const tweenToOpacity = (colorIndex): string => {
  currentPercentage += (percentage - currentPercentage) / speed;
  currentPercentage = mathHelpers.toFixed(currentPercentage, 4);

  return `rgba(${colorCombinations[colorIndex].backgroundColor.red},${
    colorCombinations[colorIndex].backgroundColor.green
  },${colorCombinations[colorIndex].backgroundColor.blue}, ${1 -
  currentPercentage})`;
};

const animate = (
  time,
  setBackgroundColor,
  colorIndex,
  requestRef,
  previousTimeRef
): void => {
  if (previousTimeRef.current !== undefined) {
    animating = false;
    setBackgroundColor(tweenToOpacity(colorIndex));
  }
  previousTimeRef.current = time;
  const diff = Math.abs(currentPercentage - percentage);

  if (diff >= 0.001) {
    animating = true;
    requestRef.current = requestAnimationFrame(delta => {
      animate(
        delta,
        setBackgroundColor,
        colorIndex,
        requestRef,
        previousTimeRef
      );
    });
  }
};

const getPercentOpacity = (position): number => {
  percentage = Math.min((Math.abs(position.y) / window.innerHeight) * 1.4, 1);
  percentage = percentage + 0.85 * percentage;
  return mathHelpers.toFixed(percentage, 4);
};


const getRandomNumber = prevNumber => {
  let newNumber = prevNumber;

  while (newNumber === prevNumber) {
    newNumber = random(colorCombinations.length - 1);
  }

  return newNumber;
};

const Splash = (): JSX.Element => {
  const [colorIndex, setColorIndex] = useLocalStorageNumber(
    'colorIndex',
    Math.max(0, colorCombinations.length - 1),
    getRandomNumber(0)
  );
  const [backgroundColor, setBackgroundColor] = useState(
    `rgba(${colorCombinations[colorIndex].backgroundColor.red},${
      colorCombinations[colorIndex].backgroundColor.green
    },${colorCombinations[colorIndex].backgroundColor.blue}, ${1})`
  );
  const [textColor, setTextColor] = useState(null);

  const requestRef = useRef();
  const previousTimeRef = useRef();

  React.useEffect(() => {
    setColorIndex(getRandomNumber(colorIndex));
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  React.useEffect(() => {
    setBackgroundColor(
      `rgba(${colorCombinations[colorIndex].backgroundColor.red},${
        colorCombinations[colorIndex].backgroundColor.green
      },${colorCombinations[colorIndex].backgroundColor.blue}, ${1})`
    );
    setTextColor(colorCombinations[colorIndex].textColor);
  }, [colorIndex]);

  React.useEffect(() => {
    const header = document.getElementsByClassName('header')[0];
    const svg = header.getElementsByTagName('svg')[0];
    svg.style.color = `${textColor}`;
  }, [textColor]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      percentage = getPercentOpacity(currPos);

      if (animating === false) {
        requestRef.current = requestAnimationFrame(delta => {
          animate(
            delta,
            setBackgroundColor,
            colorIndex,
            requestRef,
            previousTimeRef
          );
        });
      }
    },
    [backgroundColor],
    null,
    false,
    100
  );

  let style = {};

  if (textColor) {
    style = {
      backgroundColor: backgroundColor,
      color: textColor,
    };
  } else {
    return (
      <section className={styles.container}>
        <Grid>
          <div className={styles.content}> </div>
        </Grid>
      </section>
    );
  }

  return (
    <section className={styles.container} style={style}>
      <Grid>
        <div className={styles.content}>
          <h2 className={styles.slogan} style={{ color: textColor }}>
            A digital design studio run by a passionate team of designers,
            developers, thinkers, and doers, who strive to help ambitious
            companies adapt, adjust, and succeed.
          </h2>
          <div className={styles.buttonContainer}>
            <HelloButton
              href="mailto:hello@uppercase.no"
              className={styles.button}
              hoverText="Let's Talk!"
              textColor={textColor}
            >
              Let&apos;s talk!
            </HelloButton>
            <a
              href="https://weareuppercase.homerun.hr/"
              className={styles.openPositions}
              style={{ color: textColor, borderColor: textColor }}
            >
              Open positions
            </a>
          </div>
        </div>
      </Grid>
    </section>
  );
};

export default Splash;
