import React from 'react';
import CasesNav from '../../common/CasesNav';
import Footer from '../../common/Footer';
import BigParagraph from './BigParagraph';
import ClientSection from './ClientSection';
import Mobile from './Mobile';
import Splash from './Splash';
import styles from './styles.module.scss';
import WhatServicesApproach from './WhatServicesApproach';

const Home = (): JSX.Element => {
  return (
    <>
      <Splash />
      <CasesNav />
      <Mobile />
      <WhatServicesApproach />
      <div className={styles.desktop}>
        <BigParagraph />
      </div>
      <ClientSection />
      <Footer />
    </>
  );
};

export default Home;
