import { useWindowWidth } from '@react-hook/window-size';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import CasesNavItems from './NavItems';

// TODO: When mobile images are ready, follow guide here for
// art directing images: https://www.gatsbyjs.com/plugins/gatsby-image/#art-directing-multiple-images
// on multiple devices.
const dataQuery = graphql`
  query imageData {
    allCasesNavItemsJson {
      edges {
        node {
          title
          excludeFromMobile
          image {
            childImageSharp {
              fluid(maxWidth: 2560, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          mobileImage {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

const CasesNav = (): JSX.Element => {

  const data = useStaticQuery(dataQuery);
  const windowWidth = useWindowWidth();
  const isOnSmallerDevice = windowWidth <= 768;
  const imageStyle = isOnSmallerDevice ? { height: '75vh' } : { maxHeight: '100vh' };

  const items = data.allCasesNavItemsJson.edges
    .filter(item => {
      if (isOnSmallerDevice) return !item.node.excludeFromMobile;
      return item;
    })
    .map(item => {
      const sources = [
        item.node.mobileImage.childImageSharp.fluid,
        {
          ...item.node.image.childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ];
      return {
        name: item.node.title,
        image: (
          <Img
            style={imageStyle}
            loading='eager'
            fluid={sources}
          />
        ),
      };
    });

  return <CasesNavItems items={items} />;
};

export default CasesNav;
