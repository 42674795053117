import classnames from 'classnames';
import Grid from 'components/common/Grid';
import React from 'react';
import { Collapse } from 'react-collapse';
import styles from './styles.module.scss';

interface Props {
  title: string;
  component: React.ReactNode;
  open: boolean;
  onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const ToggleButton = ({
  title,
  component,
  onClick,
  open = false,
}: Props): JSX.Element => {
  return (
    <div>
      <a className={styles.button} onClick={onClick}>
        <Grid>
          <span className={classnames(styles.title, open && styles.open)}>
            {title}
          </span>
        </Grid>
      </a>
      <Collapse
        isOpened={open}
        theme={{
          collapse: styles.collapse,
          content: classnames(styles.content, open && styles.open),
        }}
      >
        {component}
      </Collapse>
    </div>
  );
};

export default ToggleButton;
