import React from 'react';
import useHover from '@react-hook/hover';
import styles from './styles.module.scss';

interface Props extends React.HTMLProps<HTMLAnchorElement> {
  hoverText: string;
  textColor: string;
}

const HelloButton = ({ href, hoverText, children, textColor }: Props) => {
  const target = React.useRef(null);
  const isHovering = useHover(target);
  const text = isHovering ? hoverText : children;
  return (
    <a
      className={styles.button}
      href={href}
      ref={target}
      style={{ color: textColor, borderColor: textColor }}
    >
      {text}
    </a>
  );
};

export default HelloButton;
