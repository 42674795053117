import classnames from 'classnames';
import Grid from 'components/common/Grid';
import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import ContactForm from './ContactForm';
import Message from './ContactForm/Message';
import styles from './styles.module.scss';

const url =
  '//uppercase.us8.list-manage.com/subscribe/post?u=51fdd26433f1127ba0ee279b2&id=eef2eab67b';

const Footer = (): JSX.Element => (
  <section className={styles.container}>
    <div className={classnames(styles.content, styles.uppercase)}>
      <Grid className={styles.contactSection}>
        <div className={styles.left}>Let&apos;s keep in touch</div>
        <div className={styles.right}>
          <p>
            Our newsletter is a monthly dose of creativity and technology for all
            the passionate problem solvers out there.
          </p>
          <p>
            Get to know more about what we work on, what keeps us up at night and
            who we are from the inside.
          </p>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status }) => (
              <div>
                <ContactForm
                  success={status === 'success'}
                  onSubmitted={(formData): void => subscribe(formData)}
                />
                {status === 'sending' && <Message message={'Sending...'} />}
                {status === 'error' && (
                  <Message
                    message={
                      'You have already signed up with this email or an error occurred. Please contact hello@uppercase.no'
                    }
                  />
                )}
              </div>
            )}
          />
        </div>
      </Grid>
      <Grid>
        <span className={classnames(styles.item, styles.colorSecondary)}>
          Let’s talk
        </span>
        <div className={styles.item}>
          <a href="https://weareuppercase.homerun.co/">Careers</a><br/><br/>
          <a href="mailto:hello@uppercase.no">hello@uppercase.no</a>
          <p>
            Drammensveien 130
            <br />
            0277 Oslo, Norway
          </p>
        </div>
        <div className={classnames(styles.item, styles.colorSecondary)}>
          <span>Follow us</span>
        </div>
        <div className={styles.item}>
          <ul>
            <li>
              <a href="https://www.instagram.com/uppercase.no/">Instagram</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/uppercase-as/">
                Linkedin
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/weareuppercase">Facebook</a>
            </li>
            <li>
              <a href="https://medium.com/uppercase-no">Medium</a>
            </li>
          </ul>
        </div>
      </Grid>
      <Grid>
        <div className={styles.shortDescription}>Digital Design Studio.</div>
      </Grid>
    </div>
  </section>
);

export default Footer;
