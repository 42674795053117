import React from 'react';
import Client from './Client';
import Gallery from './Gallery';


const items = [
  {
    menu: 'Clients',
    content: (
        <Client/>
    ),
  },
];

const ClientSection = (): JSX.Element => {
  return (
    <>
      <Gallery items={items}/>
    </>
    );
};

export default ClientSection;
