import Grid from 'components/common/Grid';
import React from 'react';
import styles from './styles.module.scss';

const BigParagraph = (): JSX.Element => (
  <section className={styles.container}>
    <Grid>
      <div className={styles.textWrapper}>
          <p className={styles.quote}>
            From early insights and strategy to design and development, we collaborate with our clients at every stage in order to create value for their business and customers.
          </p>
      </div>
    </Grid>
  </section>
);

export default BigParagraph;
