import * as mathHelpers from 'assets/helpers/math';
import useMousePosition from 'assets/helpers/useMousePosition';
import React, { useState, useRef } from 'react';
import { useScrollPosition } from '../../../../assets/helpers';
import Pagination from '../../Pagination';
import styles from './styles.module.scss';

let animating = false;
let percentage = 0;
let currentBorderPrecentage = 0;
let currentScalePrecentage = 0;
let dir = 'vh';
let skipImageInterval = null;
let skipImageIndex = -1;
const borderSpeed = 4;
const scaleSpeed = 16;

interface Item {
  name: string;
  image: React.ReactNode;
}

interface Props {
  items: Item[] | any;
}

const animate = (
  time,
  setBorderSize,
  setContainerScale,
  requestRef,
  previousTimeRef
) => {
  if (previousTimeRef.current !== undefined) {
    animating = false;
    setBorderSize(tweenToBorderScale());
    setContainerScale(tweenToContainerScale());
  }
  previousTimeRef.current = time;
  const diffBorder = Math.abs(currentBorderPrecentage - percentage);
  const diffScale = Math.abs(currentScalePrecentage - percentage);

  if (diffBorder >= 0.001 && diffScale >= 0.001) {
    animating = true;
    requestRef.current = requestAnimationFrame(delta => {
      animate(
        delta,
        setBorderSize,
        setContainerScale,
        requestRef,
        previousTimeRef
      );
    });
  }
};

function getPercent(position): number {
  const startPos = window.innerHeight * 0.35;
  const endPos = window.innerHeight * 0.5;
  const diff = endPos - startPos;
  const posInDiff = Math.max(0, Math.abs(position.y) - startPos);

  const percentage = Math.min(posInDiff, diff) / diff;

  return percentage;
}

const tweenToBorderScale = (): number => {
  currentBorderPrecentage +=
    (percentage - currentBorderPrecentage) / borderSpeed;
  currentBorderPrecentage = mathHelpers.toFixed(currentBorderPrecentage, 4);

  return 5 * currentBorderPrecentage;
};

const tweenToContainerScale = (): number => {
  currentScalePrecentage += (percentage - currentScalePrecentage) / scaleSpeed;
  currentScalePrecentage = mathHelpers.toFixed(currentScalePrecentage, 4);

  const scale = 1.1 - 0.05 * currentScalePrecentage;

  return Math.max(scale, 1);
};

const skipToImage = (active, setActive): void => {
  setActive(active);
};

const CasesNavItems = ({ items }: Props): JSX.Element => {
  const [active, setActive] = useState(0);
  const [borderSize, setBorderSize] = useState(0);
  const [containerScale, setContainerScale] = useState(1.1);
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [preloader, setPreloader] = useState(null);

  const [cursor, setCursor] = useState('');
  const { x, y } = useMousePosition();

  const requestRef = useRef();
  const previousTimeRef = useRef();


  React.useEffect(() => {
    dir = window.innerHeight < window.innerWidth ? 'vh' : 'vw';

    window.scrollTo(0, 0);

    setTimeout(() => {
      setPreloader(items);
    }, 500);

    return () => {
      cancelAnimationFrame(requestRef.current);
    }
  }, []);

  React.useEffect(() => {
    skipImageInterval = setInterval(() => {
      skipImageIndex = skipImageIndex + 1 < items.length - 1 ? skipImageIndex + 1 : 0;
      skipToImage(skipImageIndex, setActive, items, false);
    }, 3000);

    return () => {
      clearInterval(skipImageInterval);
    }
  }, [preloader]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y + 1 >= prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);

      percentage = getPercent(currPos);

      if (animating === false) {
        requestRef.current = requestAnimationFrame(delta => {
          animate(
            delta,
            setBorderSize,
            setContainerScale,
            requestRef,
            previousTimeRef
          );
        });
      }
    },
    [hideOnScroll],
    null,
    false,
    100
  );

  const containerStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    boxShadow: `inset 0 0 0 ${borderSize}${dir} black`,
    width: 'calc(100% + 1px)',
    height: '100%',
    zIndex: 10,
  };

  const imgScale = {
    // This dosnt work after changing to image set
    // transform: `scale(${containerScale})`,
    // top: 0,
  };

  const skipStyle = {
    padding: `0 ${borderSize}${dir}`,
  };

  return (
    <>
      <span className={styles.hiddenImages}>
        {preloader &&
          preloader.map((val, key) => {
            return <span key={key}>{val.image}</span>;
          })}
      </span>
      <div className={`NavItems ${cursor} ${styles.container}`}>
        <div className={styles.caseContainer}>
          <div style={containerStyle} />
          <div className={styles.image} style={imgScale}>
            {active < 0 ? items[0].image : items[active].image}
          </div>
        </div>
      </div>
      <div className={styles.gridBottom} style={skipStyle}>
        <p className={styles.caseName}>
          {active < 0 ? items[0].name : items[active].name}
        </p>
        <Pagination className={styles.pagination}>
          {active + 1} / {items.length}
        </Pagination>
      </div>
    </>
  );
};

export default CasesNavItems;
