import Home from 'components/sections/Home';
import Layout from 'layouts/Default';
import React from 'react';


const Page = (): JSX.Element => (
  <Layout>
    <Home />
  </Layout>
);

export default Page;
