import classnames from 'classnames';
import React from 'react';
import styles from './styles.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const Pagination = ({ children, className }: Props): JSX.Element => {
  return (
    <div className={classnames(styles.pagination, className)}>
      {children}
    </div>
  );
};

export default Pagination;
