import React from 'react';

interface Props {
  message: string;
}

const Message = ({ message }: Props): JSX.Element => {
  return <p>{message}</p>;
};

export default Message;
