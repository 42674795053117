import classnames from 'classnames';
import Grid from 'components/common/Grid';
import React, { useState, Fragment } from 'react';
import styles from './styles.module.scss';

interface Item {
  menu: React.ReactNode;
  firstColumn?: React.ReactNode;
  secondColumn?: React.ReactNode;
}

interface Props {
  items: Item[];
}

const TextGallery = ({ items }: Props): JSX.Element => {
  const [active, setActive] = useState(0);
  return (
    <section className={styles.container}>
      <Grid>
        <nav className={styles.wrapper}>
            {items.map((item, index) => (
              <Fragment key={`f${index}`}>
                <div className= {classnames(styles.menu, (index === 1) && styles.one, (index === 2) && styles.two)} key={index}>
                  <a className={classnames((index === active) && styles.active)} onClick={(): void => {setActive(index)}}>{item.menu}</a>
                </div>
                {index === active ?
                  <div className={styles.contentWrapper}>
                    <div className={classnames(styles.firstColumn, (index === active) && styles.fadeIn)}>
                        {items[active].firstColumn}
                    </div>
                    <div className={classnames(styles.secondColumn, (index === active) && styles.fadeIn)}>
                      {items[active].secondColumn}
                    </div>
                  </div> : null}
              </Fragment>
            ))}
        </nav>
      </Grid>
    </section>
  );
};

export default TextGallery;
