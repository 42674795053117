import React, { useState } from 'react';
import Grid from '../../../common/Grid';
import BigParagraph from '../BigParagraph';
import ToggleButton from './ToggleButton';
import styles from './styles.module.scss';

const content = [
  {
    title: 'Uppercase',
    component: (
      <Grid>
        <section className={styles.gridContent}>
          <p>
            We help businesses innovate.
            <br />
            Through engineering and design, we are connecting businesses to
            transform good ideas into great products and services.
          </p>

          <p>
            We help start-ups start up.
            <br />
            If you have a business idea, we can help you build success around
            it. For pay as well as for equity.
          </p>

          <p>
            We are creators.
            <br />
            Our team offers all aspects of the design and development process
            and take great pride in the craftsmanship. We can provide innovative
            digital products and services tailored to you, as well as pushing
            the boundaries of your existing system.
          </p>
        </section>
      </Grid>
    ),
  },
  {
    title: 'Approach',
    component: (
      <Grid>
        <section className={styles.gridContent}>
          <p>
            Our drive and purpose are to solve complex problems. We accomplish
            this by working in tight collaboration with our clients and paying
            close attention to the end-users needs.
          </p>

          <p>
            Having a transparent process in every project we take on has been
            vital regarding how we have improved and sharpened over time. After
            more than a decade on this path, we have created a close network of
            strategic partners we collaborate with when needed.
          </p>

          <p>
            With this approach, we are and have been able to produce world-class
            work for some of the world’s leading brands.
          </p>
        </section>
      </Grid>
    ),
  },
  {
    title: 'Services',
    component: (
      <Grid>
        <section className={styles.gridContent}>
          <h5>Design</h5>
          <ul>
            <li>Graphic Design</li>
            <li>User Experience</li>
            <li>User Interface</li>
            <li>Art Direction</li>
            <li>Service Design</li>
          </ul>

          <h5>Engineering</h5>
          <ul>
            <li>Back-End Development</li>
            <li>Front-End Development</li>
            <li>Software Engineering</li>
            <li>Machine Learning</li>
            <li>Artificial Intelligence</li>
            <li>Creative Tech</li>
          </ul>

          <h5>Process</h5>
          <ul>
            <li>Project Management</li>
            <li>Product Development</li>
            <li>Lean Start-up</li>
          </ul>

          <h5>Business</h5>
          <ul>
            <li>Business Development</li>
            <li>Business Strategy</li>
          </ul>

          <h5>Within Network</h5>
          <ul>
            <li>Motion and animation</li>
            <li>Illustration</li>
            <li>Spatial design</li>
            <li>Advertising and marketing</li>
            <li>Analytics and PR</li>
            <li>Experience design</li>
            <li>Photography</li>
            <li>Copywriting</li>
            <li>Content creation</li>
          </ul>

          <br />
          <br />
          <br />

          <p>&</p>
          <p>
            The thing is, we could probably list more services, and maybe we
            should? But, let’s make a deal &mdash; if you have a problem, talk
            to us. Don’t be shy and say hello.
          </p>
        </section>
      </Grid>
    ),
  },
];

const Mobile = (): JSX.Element => {
  const [openIndex, setOpenIndex] = useState(null);
  return (
    <section className={styles.container}>
      <div>
        {content.map(({ title, component }, index) => (
          <ToggleButton
            onClick={(e): void => {
              if (openIndex === index) {
                setOpenIndex(null);
              } else {
                setOpenIndex(index);
                const el = e.currentTarget;
                setTimeout(() => {
                  const { top } = el.getBoundingClientRect();
                  const currentScroll = window.scrollY;
                  window.scroll({ top: currentScroll + top });
                }, 0);
              }
              e.preventDefault();
            }}
            title={title}
            component={component}
            open={openIndex === index}
            key={index}
          />
        ))}
      </div>
      {/*
        TODO:
        "BigParagraph should probably not be here. Remove this component ("Mobile") to
        something more useful and do a check in parent container whether or not
        to render "Mobile" or desktop content.
      */}
      <BigParagraph />
    </section>
  );
};

export default Mobile;
