import React from 'react';
import styles from './styles.module.scss';

const Client = (): JSX.Element => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.line}>
          <ul className={styles.content}>
            <li>Dintero</li>
            <li>Next Day Pack</li>
            <li>Fuse</li>
            <li>DolphiTech</li>
            <li>Otovo</li>
            <li>Attensi</li>
            <li>EnterSpace</li>
            <li>Bember</li>
            <li>Outtt</li>
            <li>Vendu Tech</li>
            <li>Sanity</li>
            <li>LDH</li>
            <li>Equinor</li>
            <li>Statoil</li>
            <li>Norwegian</li>
            <li>Telenor</li>
            <li>Finn</li>
            <li>Hafslund</li>
            <li>UiB: KMD</li>
            <li>YaBank</li>
            <li>TV2 Sumo</li>
            <li>Protector</li>
            <li>Geno</li>
            <li>Bokstaven K</li>
            <li>Klaveness</li>
            <li>Jøtul</li>
            <li>Braathens IT</li>
            <li>Conax</li>
            <li>Maya Eiendom</li>
            <li>YME</li>
          </ul>
        </div>
        <div className={styles.line}>
          <ul className={styles.content}>
            <li>Otovo</li>
            <li>Attensi</li>
            <li>EnterSpace</li>
            <li>Bember</li>
            <li>Outtt</li>
            <li>Vendu Tech</li>
            <li>Boligtilvalg</li>
            <li>Dintero</li>
            <li>Next Day Pack</li>
            <li>The Future Group</li>
            <li>Fuse</li>
            <li>DolphiTech</li>


            <li>TV2 Sumo</li>
            <li>Protector</li>
            <li>Geno</li>
            <li>Bokstaven K</li>
            <li>Klaveness</li>
            <li>Jøtul</li>
            <li>Braathens IT</li>
            <li>Conax</li>
            <li>Maya Eiendom</li>
            <li>YME</li>


            <li>Norwegian</li>
            <li>Telenor</li>
            <li>Finn</li>
            <li>Hafslund</li>
            <li>UiB: KMD</li>
            <li>YaBank</li>
            <li>LDH</li>
            <li>Equinor</li>
            <li>Sanity</li>
            <li>NSB</li>
          </ul>
        </div>
        <div className={styles.line}>
          <ul className={styles.content}>
            <li>LDH</li>
            <li>Hafslund</li>
            <li>UiB: KMD</li>
            <li>YaBank</li>
            <li>NSB</li>
            <li>Jøtul</li>
            <li>Braathens IT</li>
            <li>Conax</li>
            <li>Maya Eiendom</li>
            <li>YME</li>
            <li>DolphiTech</li>
            <li>Otovo</li>
            <li>Attensi</li>
            <li>EnterSpace</li>
            <li>Vendu Tech</li>
            <li>Boligtilvalg</li>
            <li>Equinor</li>
            <li>Dintero</li>
            <li>Next Day Pack</li>
            <li>The Future Group</li>
            <li>Fuse</li>
            <li>TV2 Sumo</li>
            <li>Sanity</li>
            <li>Norwegian</li>
            <li>Telenor</li>
            <li>Finn</li>
            <li>Protector</li>
            <li>Geno</li>
            <li>Bokstaven K</li>
            <li>Klaveness</li>
            <li>Bember</li>
            <li>Outtt</li>
          </ul>
        </div>

        <div className={styles.line}>
          <ul className={styles.content}>
            <li>LDH</li>
            <li>Equinor</li>
            <li>Sanity</li>
            <li>Norwegian</li>
            <li>Telenor</li>
            <li>Finn</li>
            <li>Hafslund</li>
            <li>UiB: KMD</li>
            <li>YaBank</li>
            <li>NSB</li>

            <li>Dintero</li>
            <li>Next Day Pack</li>
            <li>The Future Group</li>
            <li>Fuse</li>
            <li>DolphiTech</li>
            <li>Otovo</li>
            <li>Attensi</li>
            <li>EnterSpace</li>
            <li>Bember</li>
            <li>Outtt</li>
            <li>Vendu Tech</li>
            <li>Boligtilvalg</li>

            <li>TV2 Sumo</li>
            <li>Protector</li>
            <li>Geno</li>
            <li>Bokstaven K</li>
            <li>Klaveness</li>
            <li>Jøtul</li>
            <li>Braathens IT</li>
            <li>Conax</li>
            <li>Maya Eiendom</li>
            <li>YME</li>
          </ul>
        </div>

        <div className={styles.line}>
          <ul className={styles.content}>
            <li>LDH</li>
            <li>Equinor</li>
            <li>Braathens IT</li>
            <li>Conax</li>
            <li>Maya Eiendom</li>
            <li>Dintero</li>
            <li>Next Day Pack</li>
            <li>EnterSpace</li>
            <li>Bember</li>
            <li>Outtt</li>
            <li>Vendu Tech</li>
            <li>Boligtilvalg</li>
            <li>TV2 Sumo</li>
            <li>Protector</li>
            <li>Geno</li>
            <li>Bokstaven K</li>
            <li>Klaveness</li>
            <li>Jøtul</li>
            <li>The Future Group</li>
            <li>Fuse</li>
            <li>DolphiTech</li>
            <li>Otovo</li>
            <li>Attensi</li>
            <li>YME</li>
            <li>Sanity</li>
            <li>Norwegian</li>
            <li>Telenor</li>
            <li>Finn</li>
            <li>Hafslund</li>
            <li>UiB: KMD</li>
            <li>YaBank</li>
            <li>NSB</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Client;
