import classnames from 'classnames';
import Grid from 'components/common/Grid';
import React from 'react';
import styles from './styles.module.scss';

interface Item {
  menu: React.ReactNode;
  content: React.ReactNode;
}

interface Props {
  items: Item[];
  className?: string;
}

const Gallery = ({ items, className }: Props): JSX.Element => {
  return (
    <section className={classnames(styles.container, className)}>
      <span className={classnames(styles.clientElementWrapper)}>
        <div className={classnames(styles.clientElement)}>CLIENTS</div>
      </span>
      <Grid>
        {items.map((item, index) => (
          <div key={index} className={classnames(styles.content)}>
            {item.content}
          </div>
        ))}
      </Grid>
    </section>
  );
};

export default Gallery;
