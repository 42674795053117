import React, { useState } from 'react';
import styles from './styles.module.scss';

interface Props {
  onSubmitted: any;
  success: boolean;
}

const ContactForm = ({ onSubmitted, success }: Props): JSX.Element => {
  const [email, setEmail] = useState('');
  return (
    <form
      onSubmit={ev => {
        ev.preventDefault();
        onSubmitted({ EMAIL: email });
        setEmail('');
      }}
      className={styles.form}
    >
      {success ? (
        <p className={styles.successMsg}>Happy to have you on board!</p>
      ) : (
        <>
          <input
            required
            type="email"
            name="email"
            placeholder="Enter your email"
            defaultValue={email}
            onChange={e => setEmail(e.target.value)}
          />
          <button type="submit">&#x2192;</button>
        </>
      )}
    </form>
  );
};

export default ContactForm;
