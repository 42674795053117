// code credit https://github.com/n8tb1t/use-scroll-position
import { useRef } from 'react'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

const isBrowser = typeof window !== `undefined`

// helper function to get the current scroll position
function getScrollPosition({ element, useWindow }) {

  // check if it runs inside the browser or return { x: 0, y: 0 }
  if (!isBrowser) return { x: 0, y: 0 }

  // check if the user requested the scroll position of the entire page or any specific element inside it
  const target = element ? element.current : document.body
  // getBoundingClientRect() is a method to get the size and the position of an element's bounding box, relative to the viewport
  const position = target.getBoundingClientRect()

  // useWindow as a parameter swither
  // in our case it swithes from window.scroll and target.getBoundingClientRect()
  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top }
}

// eslint-disable-next-line import/prefer-default-export
export function useScrollPosition(effect, deps, element, useWindow, wait) {
  // variable that stores current position coordinates
  // @ts-ignore
  const position = useRef(getScrollPosition({ useWindow }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ element, useWindow })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useIsomorphicLayoutEffect(() => {
    if (!isBrowser) {
      return
    }

    // scroll event will update position value, and call the callback only after the waiting period is over
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener('scroll', handleScroll)

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('scroll', handleScroll)
  }, deps)
}

useScrollPosition.defaultProps = {
  deps: [],
  element: false,
  useWindow: false,
  wait: null,
}
