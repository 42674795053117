
export const toFixed = (number, desimals) => {
  let des = 10;
  
  for (var i = 0; i < desimals; i++){
    des *= 10
  }
  
  return Math.round((number + Number.EPSILON) * des) / des
}

